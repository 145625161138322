export default [
  {
    id: 0,
    lineData: {
      seriesName: "Target Score per Year",
      renderAs: "line",
      color: "#F04E23",
      data: [
        {
          value: "1.5",
        },
        {
          value: "3",
        },
        {
          value: "2.5",
        },
        {
          value: "4",
        },
        {
          value: "1",
        },
      ],
    },
    lineData2: {
      seriesName: "Company Managerial Assessment Score",
      renderAs: "line",
      color: "#748FA8",
      data: [
        {
          value: "2",
        },
        {
          value: "3.5",
        },
        {
          value: "2",
        },
        {
          value: "3.3",
        },
        {
          value: "2",
        },
      ],
    },
    barData: {
      renderAs: "bar",
      data: [
        {
          value: "2",
        },
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "3",
        },
        {
          value: "1.5",
        },
      ],
    },
  },
  {
    id: 1,
    lineData: {
      seriesName: "Target Score per Year",
      renderAs: "line",
      color: "#F04E23",
      data: [
        {
          value: "3.5",
        },
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "4",
        },
        {
          value: "1.5",
        },
      ],
    },
    lineData2: {
      seriesName: "Company Managerial Assessment Score",
      renderAs: "line",
      color: "#748FA8",
      data: [
        {
          value: "2.5",
        },
        {
          value: "3.5",
        },
        {
          value: "1",
        },
        {
          value: "2.3",
        },
        {
          value: "4",
        },
      ],
    },
    barData: {
      renderAs: "bar",
      data: [
        {
          value: "3",
        },
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "3.5",
        },
        {
          value: "3",
        },
      ],
    },
  },
  {
    id: 2,
    lineData: {
      seriesName: "Target Score per Year",
      renderAs: "line",
      color: "#F04E23",
      data: [
        {
          value: "3.1",
        },
        {
          value: "2.8",
        },
        {
          value: "4",
        },
        {
          value: "3.2",
        },
        {
          value: "1.5",
        },
      ],
    },
    lineData2: {
      seriesName: "Company Managerial Assessment Score",
      renderAs: "line",
      color: "#748FA8",
      data: [
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "2.5",
        },
        {
          value: "1.8",
        },
        {
          value: "2.6",
        },
      ],
    },
    barData: {
      renderAs: "bar",
      data: [
        {
          value: "2.5",
        },
        {
          value: "3",
        },
        {
          value: "3.8",
        },
        {
          value: "3",
        },
        {
          value: "2",
        },
      ],
    },
  },
];

<template>
  <div class="mb-5">
    <div>
      <h1 class="text-xl text-grey-light font-medium font-headers">
        Results / <span class="font-bold text-grey-dark-1">History</span>
      </h1>
      <div class="flex justify-start mt-3">
        <div class="w-1/4">
          <Select
            :options="this.assessmentTypes"
            :onSelect="setAssessmentType"
            label="Assessment Type"
            :value="assessmentType"
            objects
          />
        </div>
      </div>
      <!-- Extract into component -->
      <div
        class="bg-white shadow mt-3 py-3 px-2 rounded"
        v-if="assessmentType.id === 0 || assessmentType.id === 1"
      >
        <h2 class="text-grey-light text-mdl font-bold font-headers">
          Mastery Model Assessments
        </h2>
        <h3 class="ml-2 mt-2 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Competencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.options"
              :onSelect="setValue"
              label="Competency"
              :value="selectedComp"
              objects
            />
          </div>
        </div>
        <div class="mt-2">
          <LineBarChart
            :categories="categories"
            :barData="this.chartData.barData"
            :lineData="this.chartData.lineData"
            :lineData2="this.chartData.lineData2"
            :barTitle="this.selectedComp.title"
            :barColor="this.selectedComp.color"
            :plotSpace="90"
          />
        </div>
        <div class="mt-4 border border-b-0 border-grey-light-1" />
        <h3 class="ml-2 mt-4 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Sub-competencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.subOptions"
              :onSelect="setSubcompValue"
              label="Show Subcompetencies for:"
              :value="selectedSubComp"
              objects
            />
          </div>
        </div>
        <div class="mt-5">
          <MultiChart
            :categories="this.multiChartData.categories"
            :barData="this.multiChartData.dataset"
          />
        </div>
      </div>
    </div>
    <div v-if="assessmentType.id === 0 || assessmentType.id === 2">
      <!-- Extract into component -->
      <div class="bg-white shadow mt-3 py-3 px-2 rounded">
        <h2 class="text-grey-light text-mdl font-bold font-headers">
          Solf Skills Assessments
        </h2>
        <h3 class="ml-2 mt-2 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Competencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.options2"
              :onSelect="setValue2"
              label="Competency"
              :value="selectedComp2"
              objects
            />
          </div>
        </div>
        <div class="mt-2">
          <LineBarChart
            :categories="categories"
            :barData="this.chartData2.barData"
            :lineData="this.chartData2.lineData"
            :lineData2="this.chartData2.lineData2"
            :barTitle="this.selectedComp2.title"
            :barColor="this.selectedComp2.color"
            :plotSpace="90"
          />
        </div>
        <!-- <div class="mt-4 border border-b-0 border-grey-light-1" />
        <h3 class="ml-2 mt-4 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Subcompetencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.subOptions"
              :onSelect="setSubcompValue2"
              :value="selectedSubComp2"
              label="Show Subcompetencies for:"
              objects
            />
          </div>
        </div>
        <div class="mt-5">
          <MultiChart
            :categories="this.multiChartData2.categories"
            :barData="this.multiChartData2.dataset"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import mocked from "../mock";
import { dataSource1, dataSource2 } from "../mock/subcompmock";

export default {
  name: "TeamResultsHistory",
  data: () => ({
    selectedComp: { label: "Business Acumen", color: "#1679ED", id: 0 },
    selectedComp2: {
      label: "Demonstrating Integrity and Trust",
      color: "#8E16ED",
      id: 0,
    },
    selectedSubComp: {
      label: "Corporate Social Responsability and Ethics",
      color: "#14C164",
      id: 0,
    },
    selectedSubComp2: {
      label: "Corporate Social Responsability and Ethics",
      color: "#14C164",
      id: 0,
    },
    options: [
      { label: "Business Acumen", color: "#1679ED", id: 0 },
      { label: "Category Management", color: "#FB6B71", id: 1 },
      { label: "Cost & Price Management", color: "#EC8F47", id: 2 },
    ],
    options2: [
      {
        label: "Demonstrating Integrity and Trust",
        color: "#8E16ED",
        id: 0,
      },
      { label: "Being Curious and Open-Minded", color: "#1EE5F1", id: 1 },
    ],
    subOptions: [
      {
        label: "Corporate Social Responsability and Ethics",
        color: "#14C164",
        id: 0,
      },
      { label: "Business Acumen", color: "#1679ED", id: 1 },
    ],
    categories: [
      { label: "2016" },
      { label: "2017" },
      { label: "2018" },
      { label: "2019" },
      { label: "2020" },
    ],
    assessmentTypes: [
      {
        label: "All Assessments",
        id: 0,
      },
      {
        label: "Mastery Model Assessments",
        id: 1,
      },
      {
        label: "Solf Skills Assessments",
        id: 2,
      },
    ],
    assessmentType: {
      label: "All Assessments",
      id: 0,
    },
    dataSets: mocked,
    multiDatasets: [dataSource1, dataSource2],
  }),
  computed: {
    chartData() {
      return this.dataSets.find((el) => el.id === this.selectedComp.id);
    },
    multiChartData() {
      return this.multiDatasets.find((el) => el.id === this.selectedSubComp.id);
    },
    chartData2() {
      return this.dataSets.find((el) => el.id === this.selectedComp2.id);
    },
    multiChartData2() {
      return this.multiDatasets.find(
        (el) => el.id === this.selectedSubComp2.id
      );
    },
  },
  methods: {
    setValue(val) {
      this.selectedComp = val;
    },
    setValue2(val) {
      this.selectedComp2 = val;
    },
    setSubcompValue(val) {
      this.selectedSubComp = val;
    },
    setSubcompValue2(val) {
      this.selectedSubComp2 = val;
    },
    setAssessmentType(val) {
      this.assessmentType = val;
    },
  },
};
</script>

<style></style>

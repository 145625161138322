export const dataSource1 = {
  id: 1,
  categories: [
    {
      label: "Building Relationships",
    },
    {
      label: "Business Intelligence",
    },
    {
      label: "Change Manag./Transf.",
    },
    {
      label: "Communication",
    },
    {
      label: "Decision Making",
    },
  ],
  dataset: [
    {
      seriesname: "2016 Actual",
      color: "#DFEAF8",
      data: [
        {
          value: "1",
        },
        {
          value: "1.5",
        },
        {
          value: "3",
        },
        {
          value: "1",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2016 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "2",
        },
        {
          value: "3",
        },
        {
          value: "2.5",
        },
        {
          value: "2.5",
        },
        {
          value: "4",
        },
      ],
    },
    {
      seriesname: "2016 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "2.42",
        },
        {
          value: "1.4",
        },
        {
          value: "2.85",
        },
        {
          value: "3.25",
        },
        {
          value: "2.12",
        },
      ],
    },
    {
      seriesname: "2017 Actual",
      color: "#C0D8F6",
      data: [
        {
          value: "3",
        },
        {
          value: "2.5",
        },
        {
          value: "3",
        },
        {
          value: "4",
        },
        {
          value: "3.5",
        },
      ],
    },
    {
      seriesname: "2017 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "3",
        },
      ],
    },
    {
      seriesname: "2017 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "1.23",
        },
        {
          value: "3.65",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "2",
        },
      ],
    },

    {
      seriesname: "2018 Actual",
      color: "#91BFF6",
      data: [
        {
          value: "3.5",
        },
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "4",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2018 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "3",
        },
        {
          value: "3.5",
        },
        {
          value: "3",
        },
        {
          value: "4",
        },
        {
          value: "4",
        },
      ],
    },
    {
      seriesname: "2018 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "1.23",
        },
        {
          value: "2",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2019 Actual",
      color: "#5AA0F3",
      data: [
        {
          value: "3.5",
        },
        {
          value: "4",
        },
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "3",
        },
      ],
    },
    {
      seriesname: "2019 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "2",
        },
        {
          value: "1",
        },
        {
          value: "4",
        },
      ],
    },
    {
      seriesname: "2019 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "2.13",
        },
        {
          value: "2",
        },
        {
          value: "3.5",
        },
        {
          value: "3.5",
        },
        {
          value: "3.65",
        },
      ],
    },
    {
      seriesname: "2020 Actual",
      color: "#1679ED",
      data: [
        {
          value: "2",
        },
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "2",
        },
        {
          value: "4",
        },
      ],
    },
    {
      seriesname: "2020 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "4",
        },
        {
          value: "3",
        },
        {
          value: "2",
        },
        {
          value: "3.5",
        },
        {
          value: "4",
        },
      ],
    },
    {
      seriesname: "2020 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "3.65",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "1.23",
        },
        {
          value: "2.13",
        },
      ],
    },
  ],
};

export const dataSource2 = {
  id: 0,
  categories: [
    {
      label: "Business Conduct",
    },
    {
      label: "Diversity and inclusiveness",
    },
    {
      label: "Global Citizenship",
    },
    {
      label: "Health and Safety",
    },
    {
      label: "Sustainability and Environment",
    },
  ],
  dataset: [
    {
      seriesname: "2016 Actual",
      color: "#D3F6E3",
      data: [
        {
          value: "1.23",
        },
        {
          value: "3.56",
        },
        {
          value: "0.24",
        },
        {
          value: "3.42",
        },
        {
          value: "2.42",
        },
      ],
    },
    {
      seriesname: "2016 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "1.24",
        },
        {
          value: "3.65",
        },
        {
          value: "2.35",
        },
        {
          value: "3.15",
        },
        {
          value: "1.67",
        },
      ],
    },
    {
      seriesname: "2016 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "2.42",
        },
        {
          value: "1.4",
        },
        {
          value: "2.85",
        },
        {
          value: "3.25",
        },
        {
          value: "2.12",
        },
      ],
    },
    {
      seriesname: "2017 Actual",
      color: "#B0EBCB",
      data: [
        {
          value: "2.52",
        },
        {
          value: "1.35",
        },
        {
          value: "2.24",
        },
        {
          value: "2.13",
        },
        {
          value: "1.89",
        },
      ],
    },
    {
      seriesname: "2017 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "2",
        },
        {
          value: "2.13",
        },

        {
          value: "1.23",
        },
        {
          value: "3.65",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2017 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "1.23",
        },
        {
          value: "3.65",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2018 Actual",
      color: "#7AD9A6",
      data: [
        {
          value: "2.13",
        },

        {
          value: "1.23",
        },
        {
          value: "4",
        },
        {
          value: "3.65",
        },
        {
          value: "1",
        },
      ],
    },
    {
      seriesname: "2018 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "2",
        },
        {
          value: "1.5",
        },
        {
          value: "3.65",
        },
        {
          value: "1.23",
        },
        {
          value: "2.13",
        },
      ],
    },
    {
      seriesname: "2018 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "1.23",
        },
        {
          value: "2",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2019 Actual",
      color: "#4BD089",
      data: [
        {
          value: "1.5",
        },
        {
          value: "3.65",
        },
        {
          value: "3",
        },
        {
          value: "2.13",
        },
        {
          value: "2",
        },
      ],
    },
    {
      seriesname: "2019 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "2",
        },
        {
          value: "3",
        },
        {
          value: "2.13",
        },
        {
          value: "3.65",
        },
        {
          value: "1.23",
        },
      ],
    },
    {
      seriesname: "2019 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "2.13",
        },
        {
          value: "2",
        },
        {
          value: "3.5",
        },
        {
          value: "3.5",
        },
        {
          value: "3.65",
        },
      ],
    },
    {
      seriesname: "2020 Actual",
      color: "#14C164",
      data: [
        {
          value: "2.5",
        },
        {
          value: "2.13",
        },
        {
          value: "1.23",
        },
        {
          value: "2.5",
        },
        {
          value: "3.65",
        },
      ],
    },
    {
      seriesname: "2020 Target",
      color: "#022F59", //Custom Color
      data: [
        {
          value: "3",
        },
        {
          value: "3.5",
        },
        {
          value: "1.23",
        },
        {
          value: "2.5",
        },
        {
          value: "2.13",
        },
      ],
    },
    {
      seriesname: "2020 Managerial",
      color: "#3B5E7F", //Custom Color
      data: [
        {
          value: "3.65",
        },
        {
          value: "2.13",
        },
        {
          value: "3.5",
        },
        {
          value: "1.23",
        },
        {
          value: "2.13",
        },
      ],
    },
  ],
};
